import mate from '../scripts/mate';
import slider from '../scripts/slider';

export default {
    init() {
    },
    finalize() {
        mate();
        slider();
    },
};
