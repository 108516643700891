import slider from '../scripts/slider';
import mate from '../scripts/mate';

export default {
    init() {
    },
    finalize() {
        slider();
        mate();
    },
};
