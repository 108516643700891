const youtube = () => {
    const videos = document.querySelectorAll('.video-yt');

    if( !videos.length ) return;

    const tag = document.createElement('script');
    const firstScriptTag = document.getElementsByTagName('script')[0];
    let players = [];

    tag.src = 'https://www.youtube.com/iframe_api';
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    const getFormattedDuration = dur => {
        const hours = Math.floor(dur / 60 / 60);
        const minutes = Math.floor(dur / 60) - (hours * 60);
        const seconds = dur % 60;
        
        let duration = hours > 0 ? hours.toString().padStart(2, '0') + ':' : '';
        duration += minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');

        return duration;
    }

    function onPlayerReady(yt) {
        const parent = yt.target.getIframe().parentNode;
        const cover = parent.querySelector('.video-yt-cover');
        const datas = parent.parentNode.querySelector('.highlighted-media-datas');
        let play = parent.querySelector('.video-yt-play');
        play = play ? play : parent.parentNode.querySelector('.video-yt-play');
        const durElt = parent.parentNode.querySelector('.highlighted-media-duration');
        if( durElt ) durElt.innerText = getFormattedDuration(yt.target.getDuration());

        if( !play ) return;

        play.addEventListener(
            'click',
            () => {
                play.classList.add('off');
                cover.classList.add('off');
                if( datas ) datas.classList.add('off');
                yt.target.playVideo();
            }
        );
    }

    videos.forEach(video => {
        global.onYouTubeIframeAPIReady = function() {
            players.push(new global.YT.Player(video.querySelector('.video-yt-player'), {
                height: '360',
                width: '640',
                videoId: video.dataset.id,
                events: {
                    onReady: onPlayerReady
                }
            }));
        };
    });
    
};

export default youtube;
