import { Curtains, Plane } from 'curtainsjs';
import { getGPUTier } from 'detect-gpu';

const curtains = () => {
    new CurtainsConstructor()
};

class CurtainsConstructor {
    constructor() {
        this.initCurtains()
    }

    initCurtains() {

        (async () => {
            const gpuTier = await getGPUTier();

            if (gpuTier.tier <= 2 && !gpuTier.isMobile || gpuTier.tier <= 1 && gpuTier.isMobile) {
                const videoEl = document.querySelector("#videos")
                if(!videoEl) return

                videoEl.classList.remove('grabbing-cursor')
                videoEl.style.cursor = "auto"
                return
            }

            const curtains = new Curtains({
                container: "curtains-canvas",
                pixelRatio: Math.min(1, window.devicePixelRatio), // limit pixel ratio for performance
                production: true,
                watchScroll: false,
                autoRender: true
            })
    
            this.planes = [] 
    
            this.sailsCurtains(curtains)
            this.fleetCurtains(curtains)
            this.fleetVideoCurtains(curtains)
    
            const gl = curtains.gl
        
            gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA)
    
            const visibility = new Visibility(this.planes)
            new Resize(curtains, visibility)

          })();
    }

    sailsCurtains(curtains) {
        const planeElements = document.querySelectorAll('.white-curtains-plane');
    
        planeElements.forEach((planeElement) => {
            planeElement.classList.add('curtains-active')
            const vertexShader = require('./../assets/shaders/white/vertex.glsl');
            const fragmentShader = require('./../assets/shaders/white/fragment.glsl');
        
            // set our initial parameters (basic uniforms)
            const params = {
                vertexShader: vertexShader,
                fragmentShader: fragmentShader,
                uniforms: {
                    time: {
                        name: "uTime", // uniform name that will be passed to our shaders
                        type: "1f", // this means our uniform is a float
                        value: 0,
                    },
                },
            };
        
            const plane = new Plane(curtains, planeElement, params);

            this.planes.push({
                plane: plane,
                el: planeElement
            })
        
            plane.onRender(() => {
                plane.uniforms.time.value = plane.uniforms.time.value + 0.007; // update our time uniform value
            });
        })
    }
    
    fleetCurtains(curtains) {
        const planeElements = document.querySelectorAll('.fleet-curtains-plane');
    
        if (!planeElements) return
    
        const vertexShader = require('./../assets/shaders/black/vertex.glsl');
        let fragmentShader = require('./../assets/shaders/black/fragment.glsl');
    
        let cpt = 0;
        planeElements.forEach((element) => {
            element.classList.add('curtains-active')
            cpt ++;
    
            if (element.parentElement.parentElement.parentElement.parentElement.parentElement.classList.contains('home-slider-fleet')) {
                if (cpt === 1) {
                    fragmentShader = require('./../assets/shaders/black/fragment-left.glsl');
                }
                if (cpt === 2) {
                    fragmentShader = require('./../assets/shaders/black/fragment-right.glsl');
                }
            }
    
            let resize = 1
    
            if (window.innerWidth < 1100) {
                resize = 0
            }
    
            const x = element.offsetWidth;
            const y = element.offsetHeight;
    
            // set our initial parameters (basic uniforms)
            const params = {
                vertexShader: vertexShader,
                fragmentShader: fragmentShader,
                uniforms: {
                    time: {
                        name: "uTime", // uniform name that will be passed to our shaders
                        type: "1f", // this means our uniform is a float
                        value: 0,
                    },
                    resize: {
                        name: "uResize",
                        type: "1f",
                        value: resize,
                    },
                    resolution: {
                        name: "uResolution", // uniform name that will be passed to our shaders
                        type: "2fv", // this means our uniform is a float
                        value: [y, x],
                    },
                },
            };
    
            const plane = new Plane(curtains, element, params);
            this.planes.push({
                plane: plane,
                el: element
            })
            let container = null;
    
            if(element.parentElement.parentElement.parentElement.classList.contains('slider-list')) {
                container = element.parentElement.parentElement.parentElement
            }
    
            plane.onRender(() => {
                plane.uniforms.time.value = plane.uniforms.time.value + 0.007; // update our time uniform value
    
                if (!container) return
    
                const translateX = container.style.transform.split("(").pop().split("px")[0]
    
                if (plane.relativeTranslation.x !== translateX) {
                    plane.relativeTranslation.x = translateX
                }
            });
    
            window.addEventListener('resize', (e) => {    
                plane.uniforms.resolution.value = [element.offsetHeight, element.offsetWidth];
    
                if(e.target.innerWidth < 1100 && resize === 1 || e.target.innerWidth > 1100 && resize === 0) {
                    if (e.target.innerWidth < 1100) {
                        resize = 0
                    } else {
                        resize = 1
                    }
                    plane.uniforms.resize.value = resize
                    plane.resize()
                    curtains.render()
    
                    setTimeout(() => {
                        plane.resize()
                        curtains.render()
                    }, 500)
                }
            })
        })
    }
    
    fleetVideoCurtains(curtains) {
    
        const videoEl = document.querySelector("#videos")
    
        if (!videoEl) return

        videoEl.classList.add('curtains-active')
    
        const vertexShader = require('./../assets/shaders/video/vertex.glsl')
        const fragmentShader = require('./../assets/shaders/video/fragment.glsl')
    
        // some basic parameters
        const params = {
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
        }
    
        const plane = new Plane(curtains, videoEl, params)
        this.planes.push({
            plane: plane,
            el: videoEl
        })
    
        plane.onReady(() => {
            plane.playVideos()
            plane.videos[0].playbackRate = 0
            new Animation(plane, [plane.videos[0]], videoEl)
        })
    }
}

class Visibility {
    constructor(planes) {
        this.planes = planes
        this.bindMethods()
        this.initEvents()
    }

    bindMethods() {
        this.update = this.update.bind(this)
    }

    initEvents() {
        window.addEventListener('scroll', this.update)
    }

    update() {
        const windowHeight = window.innerHeight
        this.planes.forEach((plane) => {
            const planeTop = plane.el.getBoundingClientRect().top
            const planeBottom = plane.el.getBoundingClientRect().bottom

            if ( windowHeight > planeTop && 0 < planeBottom) {
                plane.plane.visible = true
            } else {
                plane.plane.visible = false
            }
        })
    }
}

class Resize {
    constructor(curtains, visibility) {
        this.heightMemory = []
        this.heightMemory.push(document.body.offsetHeight)
        this.curtains = curtains
        this.visibility = visibility
        this.bindMethods()
        this.eventsListener()
        this.resizeCurtains()
    }

    bindMethods() {
        this.resizeCurtains = this.resizeCurtains.bind(this)
    }

    eventsListener() {
        window.addEventListener('resize', this.resizeCurtains)
        window.addEventListener('orientationchange', this.resizeCurtains)
    }

    resizeCurtains() {
        this.heightMemory.push(document.body.offsetHeight)
        window.requestAnimationFrame(this.resizeCurtains)
        const lastIndex = this.heightMemory.length - 1

        if (lastIndex-100 >= 0) {
            const lastHeight = this.heightMemory[lastIndex]
            for(let i = lastIndex-100; i <= lastIndex; i++) {
                if(this.heightMemory[i] !== lastHeight) {
                    this.curtains.resize()
                    this.visibility.update()
                    return
                }
            }
        }
    }
}

class Animation {
    constructor(plane, videos, container) {
        this.initElements(plane, videos, container);
        this.bindMethods();
        this.eventsListener();
    }

    initElements(plane, videos, container) {
        this.plane = plane;
        this.container = container;
        this.mouse = {
            position: [],
            pressed: false
        }
        this.idAnimation = -1;
        this.reverse = false;
        this.videos = videos
        this.maxTime = videos[0].duration;

        if (this.videos.length > 1) {
            setInterval(() => {
                const memo = this.videos[0].currentTime
                this.videos.forEach((video) => {
                    video.currentTime = memo
                })
            }, 500)
        }
    }

    bindMethods() {
        this.startVideo = this.startVideo.bind(this);
        this.startAnimation = this.startAnimation.bind(this);
        this.duringAnimation = this.duringAnimation.bind(this);
        this.endAnimation = this.endAnimation.bind(this);
        this.rotateFleet = this.rotateFleet.bind(this);
    }

    eventsListener() {
        this.container.addEventListener('click', this.startVideo);
        this.container.addEventListener('mousedown', this.startAnimation);
        this.container.addEventListener('touchstart', this.startAnimation);
        this.container.addEventListener('mousemove', this.duringAnimation);
        this.container.addEventListener('touchmove', this.duringAnimation);
        this.container.addEventListener('mouseup', this.endAnimation)
        this.container.addEventListener('touchend', this.endAnimation)
        this.container.addEventListener('mouseleave', this.endAnimation)
    }

    startVideo() {
        if (this.plane) {
            this.plane.playVideos()
        } else {
            this.videos.forEach((video) => {
                video.play()
            })
        }

        this.videos.forEach((video) => {
            video.playbackRate = 0
        })
    }

    getMousePosition (e) {
        return (e.clientX || e.touches[0].clientX)
    }
    
    startAnimation(e) {
        // e.preventDefault()
        this.mouse.position = []
        this.mouse.position.push(this.getMousePosition(e))
        this.maxTime = this.videos[0].duration
        this.mouse.pressed = true
        this.container.classList.add('grabbing-cursor')
    }

    duringAnimation(e) {
        // e.preventDefault()
        if (!this.mouse.pressed) return

        this.mouse.position.push(this.getMousePosition(e))

        const currentIndex = this.mouse.position.length - 1
        const oldIndex = Math.max(currentIndex - 10, 0)

        this.delta = (this.mouse.position[oldIndex] - this.mouse.position[currentIndex]) / 20

        if(this.delta < 0 && !this.reverse || this.delta > 0 && this.reverse) {
            const memo = this.videos[0].currentTime
            this.videos.forEach((video) => {
                video.currentTime = this.maxTime - memo
            })
            
            this.reverse = !this.reverse
        }

        window.cancelAnimationFrame(this.idAnimation);
        this.rotateFleet();
    }

    endAnimation(e) {
        // e.preventDefault()
        this.mouse.pressed = false;
        this.container.classList.remove('grabbing-cursor')
    }

    rotateFleet() {
        this.delta = this.lerp(this.delta, 0, 0.05);

        if(this.reverse && this.videos[0].currentTime >= this.maxTime - 0.2){
            this.videos.forEach((video) => {
                video.currentTime = this.maxTime / 2
            })
        } else if(!this.reverse && this.videos[0].currentTime >= this.maxTime/2){
            this.videos.forEach((video) => {
                video.currentTime = 0
            })
        }

        this.videos.forEach((video) => {
            video.playbackRate = Math.abs(this.delta.toFixed(1));
        })
        
        this.idAnimation = window.requestAnimationFrame(this.rotateFleet);
    }

    lerp (a, b, n) {
        return (1 - n) * a + n * b;
    }
}

export default curtains;