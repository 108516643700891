import gsap from 'gsap';
import Draggable from 'gsap/Draggable';
import InertiaPlugin from 'gsap/InertiaPlugin';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
const slider = () => {
  
  
  document.querySelectorAll('.swiper').forEach(slider => {
    console.log('aa', slider, 'b');
    new Swiper(slider, {
      modules: [Navigation],
      slidesPerView: 1,
      loop: true,
      spaceBetween: 30,
      breakpoints: {
        480: {
          slidesPerView: 3,
        }
      },
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    });
  });

    const sliders = document.querySelectorAll('.slider');

    if( !sliders.length ) return;

    const checkIfMatesAreVisible = mates => {
        mates.forEach(mate => {
            let left = mate.getBoundingClientRect().left;

            if(window.scrollX + left < 0 || window.scrollX + left + mate.clientWidth > window.outerWidth){
                mate.classList.add('off');
            }else{
                mate.classList.remove('off');
            }
        });
    };

    const init = slider => {
        const mates = slider.querySelectorAll('.slider-item');
        const drag = slider.querySelector('.slider-drag');

        if( mates.length < 2 && !slider.classList.contains('slider-current-fleet')){
            gsap.set(drag, {display: 'none'});
            return;
        }

        const list = slider.querySelector('.slider-list');

        Draggable.create(list, {
            type: 'x',
            bounds: slider,
            throwProps: true,
            onPress: () => {
                list.style.cursor = 'auto'
                if( drag.classList.contains('off') ) return;
                list.style.cursor = 'grabbing'
                gsap.to(drag, 0.2, {opacity: 0});
                drag.classList.add('on');
            },
            onRelease: () => {
                list.style.cursor = 'auto'
                if( drag.classList.contains('off') ) return;
                list.style.cursor = 'grab'
            },
            onThrowUpdate: () => checkIfMatesAreVisible(mates)
        });

        checkIfMatesAreVisible(mates);

        let dragDisplay = true;
        
        if(slider.offsetWidth >= list.offsetWidth) {
            gsap.set(drag, {display: 'none'}); 
            dragDisplay = false; 
            list.style.cursor = 'auto'
            drag.classList.add('off')
        }

        window.addEventListener('resize', () => {  
            gsap.set(list, { x: 0 })      
            if(slider.offsetWidth < list.offsetWidth && !dragDisplay) {
                gsap.set(drag, {display: 'flex'});
                dragDisplay = true;
                list.style.cursor = 'drag'
                drag.classList.remove('off')
            }

            if(slider.offsetWidth >= list.offsetWidth && dragDisplay) {
                gsap.set(drag, {display: 'none'});
                dragDisplay = false;
                list.style.cursor = 'auto'
                drag.classList.add('off')
                drag.classList.remove('on')
            }
        })
    };

    gsap.registerPlugin(Draggable, InertiaPlugin);

  sliders.forEach(slider => {
    init(slider);
  });
};

export default slider;
