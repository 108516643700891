import Cartapus from 'cartapus';
import header from '../scripts/header';
import video from '../scripts/video';
import posts from '../scripts/posts';
import curtains from '../scripts/curtains';
import youtube from '../scripts/youtube';
import parallax from '../scripts/parallax';

export default {
  init() {
    new Cartapus({
      root: null,
      rootMargin: '0px',
      threshold: 0.2,
      once: true,
      events: false
    });
  },
  finalize() {
    header();
    video();
    posts();
    curtains();
    youtube();
    parallax();
  },
};
