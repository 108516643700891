import slider from '../scripts/slider';
import raceTime from '../scripts/raceTime';

export default {
    init() {
    },
    finalize() {
        raceTime();
        slider();
    },
};
