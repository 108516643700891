//import gsap from 'gsap';

const mate = () => {
    const popin = document.getElementById('mate-popin');
    const mates = document.querySelectorAll('.mate-btn');

    if( !popin || !mates.length ) return;

    const matePerf = popin.querySelector('.block-perf-txt');
    const htmlTag = document.getElementsByTagName('html')[0];
    const content = popin.querySelector('#mate-content');
    const contentImg = popin.querySelector('.mate-img');
    const contentBefore = popin.querySelector('.mate-popin-content-before');
    const contentAfter = popin.querySelector('.mate-popin-content-after');
    const close = popin.querySelector('#mate-close');
    const loader = popin.querySelector('#mate-loader');

    const closePopin = (bg, id) => {
        if (bg === 'bg' && id !== 'mate-popin') return;

        popin.classList.remove('on');
        content.classList.remove('on');
        htmlTag.classList.remove('no-scroll');
    };

    const loadPopin = id => {
        loader.classList.add('on');
        popin.classList.add('on');
        htmlTag.classList.add('no-scroll');

        fetch(ajaxurl + '?action=spindrift_load_mate&mate_id=' + id, { method: 'POST' })
            .then(response => {
                if (response.ok) return response.json();
                return Promise.reject(response);
            })
            .then(data => {
                contentImg.innerHTML = data.result[0][0];
                contentBefore.innerHTML = data.result[0][1];
                contentAfter.innerHTML = data.result[0][2];
                matePerf.innerHTML = data.result[1];
                content.classList.add('on');
                loader.classList.remove('on');
            })
            .catch(error => {
                console.warn('Something went wrong.', error);
                closePopin();
            });
    };

    mates.forEach(mate => mate.addEventListener('click', () => loadPopin(mate.dataset.id)));

    popin.addEventListener('click', evt => closePopin('bg', evt.target.id));
    close.addEventListener('click', closePopin);
};

export default mate;
