const video = () => {
    const videos = document.querySelectorAll('video');

    if( !videos ) return;
 
    videos.forEach((video) => {
        const playerBtn = document.createElement('div');
        playerBtn.classList.add('player-btn');
        video.parentElement.appendChild(playerBtn);

        playerBtn.addEventListener('click', () => {
            video.play();
            playerBtn.classList.add('hidden');
        });

        video.addEventListener('play', () => {
            playerBtn.classList.add('hidden');
        });

        video.addEventListener('pause', () => {
            playerBtn.classList.remove('hidden');
        });
    })
};

export default video;