import Flickity from "flickity";
import "flickity/dist/flickity.css";

const carouselMedias = () => {
    new CarouselMedias()
};

class CarouselMedias {
    constructor() {
        this.initEls()
        this.initSlider()
        this.bindMethods()
        this.initEvents()
        this.sliderOpen()
    }

    initEls() {
        this.slider = document.querySelector('.carousel-medias-items')

        if( !this.slider ) return

        this.sliderItems = document.querySelectorAll('.carousel-medias-item')
        this.items = document.querySelectorAll('.single-media-item')
        this.filters = document.querySelectorAll('.single-media-filters .cat-item')
        this.popinClose = document.querySelector('.single-media-popin-close')
    }

    bindMethods() {
        if( !this.slider ) return

        this.sliderOpen = this.sliderOpen.bind(this)
        this.updateSlider = this.updateSlider.bind(this)
        this.selectItem = this.selectItem.bind(this)
        this.stopIframe = this.stopIframe.bind(this)
    }

    initEvents() {
        if( !this.slider ) return

        this.items.forEach(item => item.addEventListener('click', this.sliderOpen))

        this.filters.forEach(filter => filter.addEventListener('click', this.updateSlider))

        this.flickity.on('select', this.selectItem)
        this.flickity.on('change', this.stopIframe)

        if (!this.popinClose) return
        this.popinClose.addEventListener('click', this.stopIframe)
    }

    initSlider() {
        if( !this.slider ) return

        this.flickity = new Flickity(this.slider, {
            cellAlign: 'left',
            contain: true,
            pageDots: false,
            wrapAround: true,
            arrowShape: { 
                x0: 40,
                x1: 60, y1: 15,
                x2: 60, y2: 5,
                x3: 60
            }
        })

        this.itemsDisplay = []
        this.sliderItems.forEach(item => this.itemsDisplay.push(item))
    }

    sliderOpen(e) {
        if( !this.slider ) return

        const clikedItem = e ? e.target : this.sliderItems[0]

        if( !clikedItem ) return
        
        const index = clikedItem.dataset.nb

        this.flickity.select(index)
        if(e) this.flickity.focus()
    }

    updateSlider(e) {
        const selectedItem = e.target

        this.itemsDisplay = []

        this.sliderItems.forEach(item => this.flickity.remove(item))

        this.sliderItems.forEach(item => {
            if (item.dataset.kind === selectedItem.dataset.slug || selectedItem.dataset.slug === 'all') { this.itemsDisplay.push(item) }
        })

        this.itemsDisplay.forEach(item => this.flickity.append(item))

        this.flickity.resize()
        
    }

    selectItem(index) {
        const item = this.itemsDisplay[index]

        if (!item) return

        this.lastItem = this.currentItem
        this.currentItem = item

        const container = item.querySelector('.item-media')

        if (container.childNodes.length !== 0) return
        
        let media = null
        let cover = null

        switch (item.dataset.kind) {
            case 'image':
                media = document.createElement("img")
                media.src = item.dataset.src
                break;
            case 'youtube':
                media = document.createElement("iframe")
                media.setAttribute("src", 'https://www.youtube.com/embed/' + item.dataset.src + '?enablejsapi=1&version=3&playerapiid=ytplayer')
                media.style.width = "100%";
                media.style.height = "100%";
                break;
            case 'soundcloud':
                cover = document.createElement("img")
                cover.src = item.dataset.cover
                media = document.createElement("iframe")
                media.setAttribute("src", 'https://w.soundcloud.com/player/?url=' + item.dataset.src)
                container.appendChild(cover)
                break;
        }
        container.appendChild(media)
    }

    stopIframe(index) {
        const item = typeof index !== 'number' ? this.currentItem : this.lastItem

        if (!item) return

        const iframeCurrent = item.querySelector('iframe')
        if (!iframeCurrent) return

        const iframes = this.slider.querySelectorAll('iframe')
        iframes.forEach(iframe => {
            if (iframe && iframe === iframeCurrent) {
                let iframeSrc = iframe.src;
                iframe.src = iframeSrc; 
            }
        })
    }
}

export default carouselMedias;