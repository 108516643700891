import './style.scss';

import './assets/img/logo.svg'
import './assets/img/logo-monochrome.svg'

import Router from './util/Router';

import common from './routes/common';
import home from './routes/home';
import team from './routes/team';
import fleet from './routes/fleet';
import single from './routes/single';
import singleFleet from './routes/single-fleet';
import races from './routes/races';
import singleRace from './routes/single-race';
import singleMediaAlbum from './routes/single-media_album';
import faq from './routes/faq';
import contact from './routes/contact';
import search from './routes/search';
import media from './routes/media';

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  common,
  home,
  team,
  fleet,
  single,
  singleFleet,
  races,
  singleRace,
  singleMediaAlbum,
  faq,
  contact,
  search,
  media
});

/** Load Events */
document.addEventListener("DOMContentLoaded", function(event) {
  routes.loadEvents();
});
