const parallax = () => {
    new Parallax()
};

class Parallax {
    constructor() {
        this.initEls()
        this.bindMethods()
        this.initEvents()
    }

    initEls() {
        this.imagesBackground = document.querySelectorAll('.parallax-bg')
        this.images = document.querySelectorAll('.parallax-img')
        this.bgs = []
        this.imgs = []

        this.imagesBackground.forEach((bg) => {
            this.bgs.push({
                el: bg,
                top: bg.getBoundingClientRect().top - document.body.getBoundingClientRect().top - window.innerHeight / 2
            })
        })

        this.images.forEach((img) => {
            img.style.overflow = 'hidden'
            const el = img.querySelector('img')
            el.style.height = '110%'
            this.imgs.push({
                el: el,
                top: img.getBoundingClientRect().top - document.body.getBoundingClientRect().top - window.innerHeight / 2
            })
        })
    }

    bindMethods() {
        this.parallaxEffect = this.parallaxEffect.bind(this)
    }

    initEvents() {
        window.addEventListener('scroll', this.parallaxEffect)
    }

    parallaxEffect() {
        const y = Math.max(window.pageYOffset, 0)

        this.bgs.forEach((bg) => {
            const translate =  (y - Math.max(bg.top, 0)) * 0.05
            bg.el.style.backgroundPosition = '50% calc(' + translate + 'px + 50%)';
        })

        this.imgs.forEach((img) => {
            const translate = (y - img.top) * -0.04
            img.el.style.transform = 'translateY(' + translate + 'px)';
        })
    }
}

export default parallax;