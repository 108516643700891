import gallery from '../scripts/gallery';
import carouselMedias from '../scripts/carousel-medias';

export default {
    init() {
        console.log('media album');
    },
    finalize() {
        gallery();
        carouselMedias();
    },
};
