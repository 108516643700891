import Flickity from "flickity";
import "flickity/dist/flickity.css";

const sliderGallery = () => {
    const galleries = document.querySelectorAll('.blocks-gallery-grid');

    if( !galleries ) return;

    galleries.forEach((gallery) => {
        const imgs = gallery.querySelectorAll('.blocks-gallery-item');

        if( imgs.length < 2 ) return;

        imgs.forEach((img) => {
            img.classList.add('carousel-cell')
        });

        const carouselStatus = document.createElement('div');
        carouselStatus.classList.add('carousel-status');
        gallery.parentElement.appendChild(carouselStatus);

        const flickity = new Flickity(gallery, {
            cellAlign: 'left',
            contain: true,
            pageDots: false,
            wrapAround: true,
            arrowShape: { 
                x0: 40,
                x1: 60, y1: 15,
                x2: 60, y2: 5,
                x3: 60
              }
        });

        const updateStatus = () => {
            const slideNumber = flickity.selectedIndex + 1;
            carouselStatus.textContent = formatNumber(slideNumber) + ' / ' + formatNumber(flickity.slides.length);
        }

        const formatNumber = (x) => {
            if(x < 10) {
                return '0' + x;
            } else {
                return x;
            }
        }

        updateStatus();

        flickity.on('select', updateStatus);
    })
};

export default sliderGallery;