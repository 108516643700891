const raceTime = () => {
    const race = document.getElementById('race');

    if( !race ) return;

    const startTime = new Date(race.dataset.start).getTime();

    const getDatas = distance => {
        // seconds = Math.floor((distance % (1000 * 60)) / 1000);
        return {
            'days': Math.floor(distance / (1000 * 60 * 60 * 24)),
            'hours': Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            'minutes': Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        };
    }

    const setTwoDigits = digit => {
        return String(digit).padStart(2, '0');
    }

    const currentTimer = document.getElementById('current-race-time');
    if( currentTimer ){
        const timerCurrentRace = () => {
            const now = new Date().getTime();
            const distance = now - startTime;
            const datas = getDatas(distance);
            
            currentTimer.textContent = setTwoDigits(datas.days) + ":" + setTwoDigits(datas.hours) + ":" + setTwoDigits(datas.minutes);
        }

        const setTimer = setInterval(timerCurrentRace, 30000);
        
        timerCurrentRace();
    }

    const nextTimerDays = document.getElementById('next-race-days');
    const nextTimerHrs = document.getElementById('next-race-hrs');
    const nextTimerMins = document.getElementById('next-race-mins');
    if( nextTimerDays && nextTimerHrs && nextTimerMins ){
        const timerNextRace = () => {
            const now = new Date().getTime();
            const distance = startTime - now;
            const datas = getDatas(distance);

            nextTimerDays.textContent = datas.days;
            nextTimerHrs.textContent = datas.hours;
            nextTimerMins.textContent = datas.minutes;
        }

        const setTimer = setInterval(timerNextRace, 30000);
        
        timerNextRace();
    }
};

export default raceTime;
