import gsap from 'gsap';
import Chart from 'chart.js/auto';
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";

const performances = () => {
    const performances = document.getElementById('performances');

    if( !performances ) return;

    let getDatasAgain;

    const pilotLegend = document.getElementById('performances-pilot-legend');
    const pilotLegends = pilotLegend.querySelectorAll('span');
    const pilot = document.getElementById('performances-pilot');
    const delay = document.getElementById('perf-delay');
    const delayHeader = document.querySelectorAll('.perf-delay-header');
    const speed = document.getElementById('perf-speed');
    const speedArrow = document.getElementById('speed-arrow');
    const speedNds = document.getElementById('perf-speed-nds');
    const maxSpeed = document.getElementById('perf-speed-max');
    const maxSpeedNds = document.getElementById('perf-speed-max-nds');
    const maxSpeedIndicator = document.getElementById('speed-max-indicator');
    const heading = document.getElementById('perf-heading');
    const headingArrow = document.getElementById('heading-arrow');
    const windStrengh = document.getElementById('perf-strengh');
    const windAngle = document.getElementById('perf-angle');
    const windDir = document.getElementById('perf-dir');
    const airTemp = document.getElementById('perf-air');
    const waterTemp = document.getElementById('perf-water');
    const swell = document.getElementById('perf-swell');
    const battery = document.getElementById('perf-battery');
    const batteryRects = performances.querySelectorAll('.battery-rect');
		
    const sdWater = document.getElementById('sd-water');		
    const sdDew = document.getElementById('sd-dew');		
    const sdConductivity = document.getElementById('sd-conductivity');		
    const sdChlorophyll = document.getElementById('sd-chlorophyll');		
    const sdAir = document.getElementById('sd-air');		
    const sdPressure = document.getElementById('sd-pressure');		
    const sdSalinity = document.getElementById('sd-salinity');		
    const sdCo2 = document.getElementById('sd-co2');

    const ctxProd = document.getElementById('chart-production').getContext('2d');
    const chartProd = new Chart(ctxProd, {
        type: 'line',
        data: {
            labels: ['00h', '', '', '', '', '', '6h', '', '', '', '', '', '12h', '', '', '', '', '', '18h', '', '', '', '', '', '24H'],
        },
        options: {
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {
                        callback: value => {
                            return value + ' W';
                        }
                    }
                }
            },
            elements: {
                point: {
                    radius: 0,
                }
            },
            plugins: {
                legend: {
                    display: false
                }
            }
        }
    });
    const solar = document.getElementById('perf-solar');
    const wind = document.getElementById('perf-wind');
    const bicycle = document.getElementById('perf-bicycle');

    const cons = document.getElementById('chart-consumption');
    const ctxCons = cons.getContext('2d');
    const chartCons = new Chart(ctxCons, {
        type: 'doughnut',
        data: {
            labels: cons.dataset.labels.split(','),
        },
        options: {
            plugins: {
                legend: {
                    position: 'bottom',
                    align: 'start',
                    labels: {
                        boxWidth: 8,
                        boxHeight: 8,
                        textAlign: 'left',
                        usePointStyle: true,
                        font: {
                            size: 9
                        }
                    }
                },
                tooltip: {
                    boxWidth: 8,
                    boxHeight: 8,
                    usePointStyle: true,
                    borderWidth: 0,
                    font: {
                        size: 9
                    }
                }
            }
        }
    });
    const nav = document.getElementById('perf-nav');
    const autopilot = document.getElementById('perf-autopilot');
    const kitchen = document.getElementById('perf-kitchen');

    const circlePaths = document.querySelectorAll('.performances-circle');
    const circlePaths04 = document.querySelectorAll('.performances-circle-04');
    const circlePaths075 = document.querySelectorAll('.performances-circle-075');
    const circlePaths06 = document.querySelectorAll('.performances-circle-06');
    const circlePaths02 = document.querySelectorAll('.performances-circle-02');
    const blueBoat = document.querySelectorAll('.blue-boat');
    const redBoat = document.querySelectorAll('.red-boat');
    const boatIndicator = document.querySelectorAll('.boat-indicator');
    const paths = document.querySelectorAll('.performances-paths');

    // UTILS
    const average = arr => arr.reduce((a, b) => a + b, 0);
    const roundNb = (nb, round) => Number(nb).toFixed(round);
    const map = (value, x1, y1, x2, y2) => (value - x1) * (y2 - x2) / (y1 - x1) + x2;
    const setTwoDigits = digit => String(digit).padStart(2, '0');

    const getDatasTimer = distance => {
        return {
            'days': Math.floor(distance / (1000 * 60 * 60 * 24)),
            'hours': Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            'minutes': Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
            'seconds': Math.floor((distance % (1000 * 60)) / 1000)
        };
    }

    const timer = document.getElementById('perf-timer');
    const startTime = new Date(timer.dataset.start).getTime();
    let setTimer;
    const chrono = () => {
        const now = new Date().getTime();
        const distance = now - startTime;
        const datas = getDatasTimer(distance);
        timer.textContent = setTwoDigits(datas.days) + ":" + setTwoDigits(datas.hours) + ":" + setTwoDigits(datas.minutes) + ":" + setTwoDigits(datas.seconds);
    }

    const fillDatas = data => {
        // STEERING
        if( data.influx.pilot_mode && data.influx.pilot_mode[1] !== undefined ){
            if( data.influx.pilot_mode[1] >= 1 ){
                pilotLegends[0].className = 'on';
                pilotLegends[1].className = '';
                gsap.to(pilot, 0.2, {x: 0});
            }else{
                pilotLegends[0].className = '';
                pilotLegends[1].className = 'on';
                gsap.to(pilot, 0.2, {x: '64px'});
            }
        }

        // DELAY
        if( data.geovoile.locations && data.geovoile.locations[0].location[0].advance ){
            delay.innerText = roundNb(data.geovoile.locations[0].location[0].advance, 3);
            if(delayHeader) delayHeader.forEach(elt => elt.innerText = roundNb(data.geovoile.locations[0].location[0].advance, 3)); 
            if(data.geovoile.locations[0].location[0].advance > 0){
                // spindrift in advance, blue boat forward
                gsap.to(blueBoat, 0.2, {x: 0});
                gsap.to(redBoat, 0.2, {x: 0});
                gsap.to(boatIndicator, {stroke: '#00af66'});
            }else if(data.geovoile.locations[0].location[0].advance < 0){
                gsap.to(blueBoat, 0.2, {x: '-18'});
                gsap.to(redBoat, 0.2, {x: '18'});
                gsap.to(boatIndicator, {stroke: '#c12424'});
            }
        }
        
        // SPEED
        if( data.influx.BSP && data.influx.BSP[1] ){
            speed.innerText = roundNb(data.influx.BSP[1], 0);
            speedNds.innerText = roundNb(data.influx.BSP[1] / 1.852, 2);
            gsap.to(speedArrow, 0.3, {rotation: map(data.influx.BSP[1], 0, 110, -34, 214)});
        }

        // MAX SPEED
        if( data.geovoile.max_speed ){
            maxSpeed.innerText = roundNb(data.geovoile.max_speed, 0);
            maxSpeedNds.innerText = roundNb(data.geovoile.max_speed / 1.852, 2);
            gsap.to(maxSpeedIndicator, 0.3, {drawSVG: map(data.geovoile.max_speed, 0, 110, 0, 100) + '%'});
        }

        // HEADING
        if( data.influx.COG && data.influx.COG[1] ){
            heading.innerText = roundNb(data.influx.COG[1], 0) + '°';
            gsap.to(headingArrow, 0.3, {rotation: roundNb(data.influx.COG[1], 0)});
        }

        // ENERGY PROD LEGEND
        if( data.influx.energy_solar && data.influx.energy_solar[1] && data.influx.energy_solar[1][0] ) solar.innerText = roundNb(data.influx.energy_solar[1][0], 0) + ' W';
        if( data.influx.energy_wind && data.influx.energy_wind[1] && data.influx.energy_wind[1][0] ) wind.innerText = roundNb(data.influx.energy_wind[1][0], 0) + ' W';
        if( data.influx.energy_bike && data.influx.energy_bike[1] && data.influx.energy_bike[1][0] ) bicycle.innerText = roundNb(data.influx.energy_bike[1][0], 0) + ' W';

        // ENERGY PROD CHART
        if( (data.influx.energy_solar && data.influx.energy_solar[1]) || (data.influx.energy_wind && data.influx.energy_wind[1]) || (data.influx.energy_bike && data.influx.energy_bike[1]) ){
            chartProd.data.datasets = [
                {
                    label: solar.dataset.label,
                    data: data.influx.energy_solar[1],
                    fill: false,
                    borderColor: '#FFFF00',
                    tension: 0.3,
                    borderWidth: 1
                },
                {
                    label: wind.dataset.label,
                    data: data.influx.energy_wind[1],
                    fill: false,
                    borderColor: '#0093B2',
                    tension: 0.3,
                    borderWidth: 1
                },
                {
                    label: bicycle.dataset.label,
                    data: data.influx.energy_bike[1],
                    fill: false,
                    borderColor: '#00AF66',
                    tension: 0.3,
                    borderWidth: 1
                }
            ];
            chartProd.update('none');
        }

        // ENERGY CONS LEGEND
        if( data.influx.energy_navigation && data.influx.energy_navigation[1] && data.influx.energy_navigation[1][0] ) nav.innerText = roundNb(data.influx.energy_navigation[1][0], 0) + ' W';
        if( data.influx.energy_auto_pilot && data.influx.energy_auto_pilot[1] && data.influx.energy_auto_pilot[1][0] ) autopilot.innerText = roundNb(data.influx.energy_auto_pilot[1][0], 0) + ' W';
        if( data.influx.energy_kitchen && data.influx.energy_kitchen[1] && data.influx.energy_kitchen[1][0] ) kitchen.innerText = roundNb(data.influx.energy_kitchen[1][0], 0) + ' W';
        
        // ENERGY CONS CHART
        if( (data.influx.energy_navigation && data.influx.energy_navigation[1]) || (data.influx.energy_auto_pilot && data.influx.energy_auto_pilot[1]) || (data.influx.energy_kitchen && data.influx.energy_kitchen[1]) || (data.influx.energy_comfort && data.influx.energy_comfort[1]) || (data.influx.energy_security && data.influx.energy_security[1]) || (data.influx.energy_communication && data.influx.energy_communication[1]) ){
            chartCons.data.datasets = [
                {
                    label: '',
                    data: [
                        average(data.influx.energy_navigation[1]).toFixed(2),
                        average(data.influx.energy_auto_pilot[1]).toFixed(2),
                        average(data.influx.energy_security[1]).toFixed(2),
                        average(data.influx.energy_kitchen[1]).toFixed(2),
                        average(data.influx.energy_comfort[1]).toFixed(2),
                        average(data.influx.energy_communication[1]).toFixed(2)
                    ],
                    backgroundColor: [
                        '#006BA6',
                        '#009775',
                        '#002F6C',
                        '#00594F',
                        '#004B87',
                        '#008578'
                    ],
                    borderWidth: 0,
                    hoverOffset: 1,
                    hoverBorderWidth: 0
                }
            ];
            chartCons.update('none');
        }

        // BATTERY
        if( data.influx.energy_indicator ){
            battery.innerText = roundNb(data.influx.energy_indicator*100, 0) + '%';

            gsap.to(batteryRects, 0.3, {opacity: 0.15});
            if( data.influx.energy_indicator > 0 && data.influx.energy_indicator < 0.25 ){
                gsap.to(batteryRects[0], 0.3, {stagger: 0.1, opacity: 0.5});
            }else if( data.influx.energy_indicator >= 0.25 && data.influx.energy_indicator < 0.5 ){
                gsap.to(batteryRects[0], 0.3, {stagger: 0.1, opacity: 1});
                gsap.to(batteryRects[1], 0.3, {stagger: 0.1, opacity: 0.5});
            }else if( data.influx.energy_indicator >= 0.5 && data.influx.energy_indicator < 0.75 ){
                gsap.to([batteryRects[0], batteryRects[1]], 0.3, {stagger: 0.1, opacity: 1});
                gsap.to(batteryRects[2], 0.3, {stagger: 0.1, opacity: 0.5});
            }else if( data.influx.energy_indicator >= 0.75 && data.influx.energy_indicator < 1 ){
                gsap.to([batteryRects[0], batteryRects[1], batteryRects[2]], 0.3, {stagger: 0.1, opacity: 1});
                gsap.to(batteryRects[3], 0.3, {stagger: 0.1, opacity: 0.5});
            }else if( data.influx.energy_indicator >= 1 ){
                gsap.to(batteryRects, 0.3, {stagger: 0.1, opacity: 1});
            }
        }

        // METEO
        if( data.influx.TWS && data.influx.TWS[1] ) windStrengh.innerText = data.influx.TWS[1] + ' KM/H';
        if( data.influx.TWA && data.influx.TWA[1] ) windAngle.innerText = data.influx.TWA[1] + '°';
        if( data.influx.TWD && data.influx.TWD[1] ) windDir.innerText = data.influx.TWD[1] + '°';
        if( data.influx.AirTemperature && data.influx.AirTemperature[1] ) airTemp.innerText = data.influx.AirTemperature[1] + ' °C';
        if( data.influx.SeaTemp && data.influx.SeaTemp[1] ) waterTemp.innerText = data.influx.SeaTemp[1] + ' °C';
        if( data.influx.SmartHeave && data.influx.SmartHeave[1] ) swell.innerText = data.influx.SmartHeave[1] + ' M';

        // SCIENTIFIC DATA
        if( data.influx.Meas_waterTemp && data.influx.Meas_waterTemp[1] ) sdWater.innerText = roundNb(data.influx.Meas_waterTemp[1], 3);
        if( data.influx.Meas_H2Odew && data.influx.Meas_H2Odew[1] ) sdDew.innerText = roundNb(data.influx.Meas_H2Odew[1], 3);
        if( data.influx.Meas_waterCond && data.influx.Meas_waterCond[1] ) sdConductivity.innerText = roundNb(data.influx.Meas_waterCond[1], 3);
        if( data.influx.Meas_Chlorophyll && data.influx.Meas_Chlorophyll[1] ) sdChlorophyll.innerText = roundNb(data.influx.Meas_Chlorophyll[1], 3);
        if( data.influx.Meas_AirTemp && data.influx.Meas_AirTemp[1] ) sdAir.innerText = roundNb(data.influx.Meas_AirTemp[1], 3);
        if( data.influx.Meas_AirPress && data.influx.Meas_AirPress[1] ) sdPressure.innerText = roundNb(data.influx.Meas_AirPress[1], 3);
        if( data.influx.Meas_salinity && data.influx.Meas_salinity[1] ) sdSalinity.innerText = roundNb(data.influx.Meas_salinity[1], 3);
        if( data.influx.Meas_CO2 && data.influx.Meas_CO2[1] ) sdCo2.innerText = roundNb(data.influx.Meas_CO2[1], 3);
        
        // CALL DATAS AGAIN EVERY MINUTE
        clearTimeout(getDatasAgain);
        getDatasAgain = setTimeout(getDatas, 60000);
    }

    const getDatas = firstTime => {
        fetch('/wp-json/spindrift/performance', {
            method: 'GET',
            credentials: 'same-origin',
            headers : {
                'Content-Type': 'application/json',
                'X-WP-Nonce' : apiurl.nonce
            }
        })
        .then(response => {
            if (response.ok) return response.json();
            return Promise.reject(response);
        })
        .then(data => {
            //console.log(data);
            if( firstTime ){
                // if arriving on page make all counters and stuff appear
                gsap.to(circlePaths04, 0.2, {stagger: 0.1, opacity: 0.4, scale: 1, delay: 0.1});
                gsap.to(circlePaths075, 0.2, {stagger: 0.1, opacity: 0.75, scale: 1, delay: 0.1});
                gsap.to(circlePaths06, 0.2, {stagger: 0.1, opacity: 0.6, scale: 1, delay: 0.1});
                gsap.to(circlePaths02, 0.2, {stagger: 0.1, opacity: 0.2, scale: 1, delay: 0.1});
                gsap.to(circlePaths, 0.2, {stagger: 0.1, opacity: 1, scale: 1, delay: 0.1, onComplete: () => {
                    gsap.to(speedArrow, 0.3, {rotation: 200, delay: 0.5});
                    gsap.to(maxSpeedIndicator, 0.2, {drawSVG: '80%', delay: 0.5});
                }});
                gsap.to(paths, 0.2, {stagger: 0.05, opacity: 1, onComplete: fillDatas, onCompleteParams: [data] });
                if( timer ) setTimer = setInterval(chrono, 1000);
            }else{
                fillDatas(data);
            }
        })
        .catch(error => {
            console.warn('Something went wrong.', error);
        });
    }

    gsap.registerPlugin(DrawSVGPlugin);
    
    gsap.set([circlePaths, circlePaths04, circlePaths075, circlePaths06, circlePaths02], {scale: 0.7, transformOrigin: '50% 50%'});
    gsap.set(headingArrow, {transformOrigin: '50% 50%'});
    gsap.set(speedArrow, {transformOrigin: '100% 50%'});
    gsap.set(maxSpeedIndicator, {drawSVG: '0%', rotation: -90});

    getDatas(true);
};

export default performances;
