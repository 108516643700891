const faqList = () => {
    new FaqList();
};

class FaqList {
    constructor() {
        this.initEls()
        this.initTransition()
        this.bindMethods()
        this.initEvents()
    }

    initEls() {
        this.items = document.querySelectorAll('.faq-question-container')
        this.itemsOpen = {
            el: null,
            cross: null
        }
    }

    bindMethods() {
        this.toggleItem = this.toggleItem.bind(this)
    }

    initEvents() {
        this.items.forEach((item) => {
            item.addEventListener('click', this.toggleItem)
        })
    }

    initTransition() {
        this.items.forEach((item) => {
            const answer = item.querySelector('.faq-answer')
            answer.style.maxHeight = answer.offsetHeight + 'px'
            item.classList.add('question-close')
        })
    }

    toggleItem(e) {
        console.log(e.target)
        const item = e.target
        if (this.itemsOpen.el && this.itemsOpen.cross && this.itemsOpen.el !== item) {
            this.itemsOpen.el.classList.add('question-close')
            this.itemsOpen.cross.classList.add('cross-close')
        }

        this.itemsOpen.el = item
        this.itemsOpen.cross = this.itemsOpen.el.querySelector('.faq-question-cross')

        this.itemsOpen.el.classList.toggle('question-close')
        this.itemsOpen.cross.classList.toggle('cross-close')
    }
}

export default faqList;
