import faqList from '../scripts/faq-list';

export default {
    init() {
        console.log('faq')
    },
    finalize() {
        faqList();
    },
};
