const handleFields = fields => {
    if (!fields.length) return;

    const checkIfEmpty = input => {
        input.value ? input.closest('.field').classList.add('on') : input.closest('.field').classList.remove('on');
    };

    fields.forEach(field => {
        const input = field.querySelector('input, textarea, select');

        if ( !input ) return;

        if( input.tagName !== 'SELECT' ) checkIfEmpty(input);

        input.addEventListener('focusin', e => e.target.closest('.field').classList.add('on'));
        input.addEventListener('focusout', e => checkIfEmpty(e.target));
    });
}

const handleFileInputs = files => {
    if (!files.length) return;

    files.forEach(wrapper => {
        const input = wrapper.querySelector('input');

        if (!input) return;

        input.style.display = 'none';

        const label = wrapper.querySelector('label');
        if(label) label.style.display = 'none';

        const btn = document.createElement('button');
        let btnHTML = '<svg class="icon"><use xlink:href="#icon-clip"></use></svg>';
        btnHTML += label ? label.textContent : 'Add file';
        btn.type = 'button';
        btn.className = 'btn-file';
        btn.innerHTML = btnHTML;
        wrapper.append(btn);

        btn.addEventListener('click', () => input.click());
        input.addEventListener('change', e => {
            if( input.files.length && input.files[0] ) btn.textContent = input.files[0].name
        });
    });
}

const forms = () => {
    handleFields(document.querySelectorAll('.field'));
    handleFileInputs(document.querySelectorAll('.field-file'));
};

export default forms;