import gsap from 'gsap';

const header = () => {
		const main 	 	 = document.body;
    const header 	 = document.getElementById('header');
		const popupmap = document.getElementById('popup-map');

    if( !header ) return;

    const burger 		= header.querySelector('#burger');
    const close 		= header.querySelector('#close-burger');
		const stickymap = header.querySelector('#cta-map');
		const mapframe 	= popupmap.querySelector('#mapframe');
		const closemap 	= popupmap.querySelector('.close-popup-map');

    const menu = header.querySelector('#menu-burger');
    const navMenuClosed = header.querySelector('#nav-menu-closed');
    const navMenuOpen = header.querySelector('#nav-menu-open');

    burger.addEventListener('click', () => {
        burger.blur();
        header.classList.add('menu-open');
        menu.classList.add('on');
        navMenuClosed.classList.add('off');
        navMenuOpen.classList.add('on');
    });

    close.addEventListener('click', () => {
        close.blur();
        header.classList.remove('menu-open');
        menu.classList.remove('on');
        navMenuClosed.classList.remove('off');
        navMenuOpen.classList.remove('on');
    });
		
		if(stickymap) {
			stickymap.addEventListener('click', () => {
					stickymap.blur();
					mapframe.src = 'https://spindrift-racing.geovoile.com/tropheejulesverne/2022/tracker/?skin=embed';
					main.classList.add('popupmap-open');
			});
	
			closemap.addEventListener('click', () => {
					main.classList.remove('popupmap-open');
					mapframe.src = '';				
			});
		}
};

export default header;
