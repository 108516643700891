import raceTime from '../scripts/raceTime';
import slider from '../scripts/slider';
import posts from '../scripts/posts';
import carouselMedias from '../scripts/carousel-medias';

export default {
    init() {
    },
    finalize() {
        raceTime();
        slider();
        posts();
        carouselMedias();
    },
};
