import gsap from 'gsap';

const posts = () => {
    const container = document.getElementById('posts');
    const wrapper = document.getElementById('post-list');
    const btnSeeMore = document.getElementById('more-posts');

    if (!container || !wrapper || !btnSeeMore) return

    const nbMaxPosts = container.dataset.posts;

    let nbPosts = container.querySelectorAll('.post').length;

    if (nbPosts % nbMaxPosts !== 0) {
        btnSeeMore.style.display = 'none';
        return;
    }

    const category = container.dataset.cat;
    const sticky = document.getElementById('sticky');
    const idSticky = sticky ? sticky.dataset.id : '';

    btnSeeMore.addEventListener('click', e => {
        e.stopImmediatePropagation();

        fetch(ajaxurl + '?action=spindrift_load_posts&id_sticky=' + idSticky + '&nb_posts=' + nbPosts + '&cat=' + category , { method: 'POST' })
        .then(response => {
            if (response.ok) return response.json();
            return Promise.reject(response);
        })
        .then(data => {
            const div = document.createElement('ul');
            div.classList.add('posts');
            div.innerHTML = data.result;
            wrapper.append(div);

            nbPosts = container.querySelectorAll('.post').length;
            if (nbPosts % nbMaxPosts !== 0) btnSeeMore.style.display = 'none';

            const newPosts = container.querySelectorAll('.post-new');
            gsap.set(newPosts, {opacity: 0, y: 30});
            gsap.to(newPosts, {
                y: 0,
                opacity: 1,
                stagger: 0.05
            });
            newPosts.forEach(newPost => newPost.classList.remove('post-new'));
        })
        .catch(error => {
            console.warn('Something went wrong.', error);
        });
    });
};

export default posts;
