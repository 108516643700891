import slider from '../scripts/slider';
import mate from '../scripts/mate';
import raceTime from '../scripts/raceTime';
import performances from '../scripts/performances';

export default {
    init() {
    },
    finalize() {
        raceTime();
        slider();
        mate();
        performances();
    },
};
