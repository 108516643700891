import gsap from 'gsap';

const gallery = () => {
    new Gallery();
};

class Gallery {
    constructor() {
        this.initEls()
        this.bindMethods()
        this.initEvents()
    }

    initEls() {
        this.gallery = document.getElementById('gallery');

        if( !this.gallery ) return;

        this.filters = this.gallery.querySelectorAll('.cat-item')
        this.items = this.gallery.querySelectorAll('.single-media-item')
        this.itemsBtn = this.gallery.querySelectorAll('.single-media-btn')
        this.itemsDisplay = {
            old: [],
            current: this.items
        }
        this.popin = this.gallery.querySelector('.single-media-popin')
        this.popinClose = this.gallery.querySelector('.single-media-popin-close')
        this.slider = this.gallery.querySelector('.carousel-medias')
        this.slider.classList.add('none')
    }

    bindMethods() {
        if( !this.gallery ) return;

        this.sortItem = this.sortItem.bind(this)
        this.openPopin = this.openPopin.bind(this)
        this.closePopin = this.closePopin.bind(this)
    }

    initEvents() {
        if( !this.gallery ) return;

        this.filters.forEach(filter => filter.addEventListener('click', this.sortItem))
        console.log(this.itemsBtn)
        this.itemsBtn.forEach(item => item.addEventListener('click', this.openPopin))

        if (!this.popinClose) return

        this.popin.addEventListener('click', this.closePopin)
        this.popinClose.addEventListener('click', this.closePopin)
    }

    sortItem(e) {
        const selectedItem = e.target
        
        if (selectedItem.classList.contains('current-cat')) return
        this.filters.forEach(filter => filter.classList.remove('current-cat'))
        selectedItem.classList.add('current-cat')

        this.itemsDisplay.old = this.itemsDisplay.current
        if (selectedItem.dataset.slug === 'all') { this.itemsDisplay.current = this.items }
        else {
            this.itemsDisplay.current = []
            this.items.forEach(item => {
                if (item.dataset.kind === selectedItem.dataset.slug) { this.itemsDisplay.current.push(item) }
            })
        }

        const tl = gsap.timeline()
      
        tl.to(this.itemsDisplay.old, {
            opacity: 0, 
            duration: 0.2,
            ease: 'power2.out'
        })
        .set(this.itemsDisplay.old, {
            display: 'none',
        })
        .set(this.itemsDisplay.current, {
            display: 'block',
            y: 20,
            opacity: 0
        })
        .to(this.itemsDisplay.current, {
            opacity: 1, 
            y: 0,
            stagger: 0.08,
            duration: 0.6,
            ease: 'power2.out'
        })
    }

    openPopin() {
        this.popin.classList.remove('none')
        document.body.style.overflowY = 'hidden'
        setTimeout(() => {
            this.slider.classList.remove('none')
        }, 550)
    }

    closePopin(e) {
        if(e.target.classList.contains('single-media-popin') || e.target.classList.contains('single-media-popin-close') || e.target.parentElement.classList.contains('single-media-popin-close')) {
            this.popin.classList.add('none');
            this.slider.classList.add('none');
            document.body.style.overflowY = 'auto'
        }
    }
}

export default gallery;
