import slider from '../scripts/slider';

export default {
    init() {
        console.log('fleet')
    },
    finalize() {
        slider();
    },
};
