import sliderGallery from '../scripts/slider-gallery';
import mate from '../scripts/mate';

export default {
    init() {
        console.log('single');
    },
    finalize() {
        sliderGallery();
        mate();
    },
};
